import { TRANSLATIONS_PROPERTY_NAME, TRANSLATION_FLAG_SUFFIX } from "@devowl-wp/api";
import { DEFAULT_LANGUAGE, EContentBlockerVisualContentType, EExtendsMergeStrategy, ServiceTemplateField, TemplateMetaDataField } from "@devowl-wp/api-real-cookie-banner";
import { useServiceTemplatePurposesFromEnum } from "../../components/route/real-cookie-banner/template";
import { SORTER_ORDER_POSITION } from "../../types/translation";
import { mapVersionToSemver } from "./version";
function mapFromFormTranslations(entry) {
    if (entry) {
        return Object.keys(entry).map((languageKey)=>{
            const langEntry = entry[languageKey];
            if (langEntry && Object.values(langEntry).join("").length > 0) {
                return {
                    language: languageKey,
                    ...langEntry
                };
            } else {
                return undefined;
            }
        }).filter(Boolean);
    }
    return undefined;
}
function mapToFormTranslationEntries(translations) {
    const formTranslations = translations === null || translations === void 0 ? void 0 : translations.map((translation)=>{
        const deepCopy = structuredClone(translation);
        const { language } = deepCopy;
        delete deepCopy.language;
        return {
            [language]: deepCopy
        };
    });
    if ((formTranslations === null || formTranslations === void 0 ? void 0 : formTranslations.length) > 0) {
        return formTranslations.reduce((prev, curr)=>{
            Object.assign(prev ? prev : {}, curr);
            return prev;
        });
    } else {
        return {};
    }
}
function mapFromFormPurposesIntoServiceTemplate(template, templateForm) {
    var _purposes_filter;
    const purposes = structuredClone(templateForm === null || templateForm === void 0 ? void 0 : templateForm.getFieldValue(ServiceTemplateField.Purposes));
    template.purposes = purposes === null || purposes === void 0 ? void 0 : (_purposes_filter = purposes.filter((purpose)=>{
        var _purpose_answer_trim, _purpose_answer;
        return ((_purpose_answer = purpose.answer) === null || _purpose_answer === void 0 ? void 0 : (_purpose_answer_trim = _purpose_answer.trim()) === null || _purpose_answer_trim === void 0 ? void 0 : _purpose_answer_trim.length) > 0;
    })) === null || _purposes_filter === void 0 ? void 0 : _purposes_filter.map((purpose, index)=>{
        // Set new orderPosition property based on the iteration index
        purpose.orderPosition = index;
        // Check if translations exist and use a conversion function
        const purposeTranslations = purpose.translations;
        if (purposeTranslations) {
            purpose.translations = mapFromFormTranslations(purposeTranslations);
        }
        return purpose;
    });
    return template;
}
function mapFromFormTranslationsIntoContentBlockerTemplate(template, templateForm) {
    const contentBlockerTranslations = structuredClone(templateForm === null || templateForm === void 0 ? void 0 : templateForm.getFieldValue(TRANSLATIONS_PROPERTY_NAME));
    if (contentBlockerTranslations) {
        template.translations = mapFromFormTranslations(contentBlockerTranslations);
    } else {
        template.translations = undefined; // no translation
    }
    return template;
}
function mapFromFormTranslationsIncludingProviderIntoServiceTemplate(template, templateForm) {
    const serviceTranslations = structuredClone(templateForm === null || templateForm === void 0 ? void 0 : templateForm.getFieldValue(TRANSLATIONS_PROPERTY_NAME));
    const providerTranslations = structuredClone(templateForm === null || templateForm === void 0 ? void 0 : templateForm.getFieldValue([
        "provider",
        TRANSLATIONS_PROPERTY_NAME
    ]));
    if (serviceTranslations) {
        template.translations = mapFromFormTranslations(serviceTranslations);
    } else {
        template.translations = undefined; // no translation
    }
    if (providerTranslations) {
        Object.keys(providerTranslations).map((languageKey)=>{
            const translation = template.translations.find((translation)=>translation.language === languageKey);
            const providerTranslation = providerTranslations[languageKey];
            // Translation or provider translations might be not available, check them. (CU-8693mh589)
            if (translation) {
                if (providerTranslation) {
                    translation.provider = {
                        ...providerTranslation
                    };
                }
                delete translation.provider[TRANSLATIONS_PROPERTY_NAME]; // form translation deletion
            }
        });
    }
    if (template.provider) {
        delete template.provider[TRANSLATIONS_PROPERTY_NAME]; // form translation deletion
    }
    return template;
}
function mapFromFormDynamicFieldsIntoServiceTemplate(template, templateForm) {
    const dynamicFields = structuredClone(templateForm.getFieldValue(ServiceTemplateField.DynamicFields));
    if (dynamicFields) {
        // Map over each dynamic field to access its translations and update them
        template.dynamicFields = dynamicFields === null || dynamicFields === void 0 ? void 0 : dynamicFields.map((dynamicField, index)=>{
            dynamicField.orderPosition = index;
            const dynamicFieldTranslations = dynamicField.translations;
            if (dynamicFieldTranslations) {
                dynamicField.translations = mapFromFormTranslations(dynamicFieldTranslations);
            } else {
                dynamicField.translations = undefined; // no translation
            }
            return dynamicField;
        });
    }
    return template;
}
function mapFromFormTechnicalDefinitionsIntoServiceTemplate(template, templateForm) {
    const technicalDefinitions = structuredClone(templateForm.getFieldValue(ServiceTemplateField.TechnicalDefinitions));
    if (technicalDefinitions) {
        template.technicalDefinitions = technicalDefinitions.map((technicalDefinition, index)=>{
            const technicalDefinitionTranslations = technicalDefinition.translations;
            technicalDefinition.orderPosition = index;
            if (technicalDefinitionTranslations) {
                technicalDefinition.translations = mapFromFormTranslations(technicalDefinitionTranslations);
            } else {
                technicalDefinition.translations = undefined; // no translations
            }
            return technicalDefinition;
        });
    }
    return template;
}
function getInitialFormValueContentBlocker(contentBlockerTemplate) {
    const templateTranslations = mapToFormTranslationEntries(contentBlockerTemplate === null || contentBlockerTemplate === void 0 ? void 0 : contentBlockerTemplate.translations);
    const value = contentBlockerTemplate ? {
        ...contentBlockerTemplate,
        minRequiredRcbVersion: (contentBlockerTemplate === null || contentBlockerTemplate === void 0 ? void 0 : contentBlockerTemplate.minRequiredRcbVersion) ? mapVersionToSemver(contentBlockerTemplate === null || contentBlockerTemplate === void 0 ? void 0 : contentBlockerTemplate.minRequiredRcbVersion) : contentBlockerTemplate === null || contentBlockerTemplate === void 0 ? void 0 : contentBlockerTemplate.minRequiredRcbVersion,
        // ===== Translation Properties ======
        translations: templateTranslations,
        successorOfIdentifier: contentBlockerTemplate.successorOfIdentifier || []
    } : {
        language: DEFAULT_LANGUAGE,
        isDeleted: false,
        minRequiredRcbVersion: "0.0.0",
        isVisualDarkMode: false,
        shouldForceToShowVisual: false,
        visualContentType: EContentBlockerVisualContentType.Generic,
        successorOfIdentifier: []
    };
    initializeTranslationFlags(value);
    return value;
}
/**
 * Initializes translation flag fields within a form value object. This function sets empty arrays for any properties
 * ending with a specific suffix (TRANSLATION_FLAG_SUFFIX) if they are null or undefined. It recursively handles nested objects.
 *
 * @param formValue - The object representing the form value which may contain translation flags.
 */ function initializeTranslationFlags(formValue) {
    if (formValue !== null && typeof formValue === "object") {
        Object.keys(formValue).forEach((key)=>{
            // If the property ends with "TranslationFlags" and is null/undefined, set it to []
            if (key.endsWith(TRANSLATION_FLAG_SUFFIX) && (formValue[key] === null || formValue[key] === undefined)) {
                formValue[key] = [];
            }
            // If the property is an object, recursively call this function
            if (typeof formValue[key] === "object") {
                initializeTranslationFlags(formValue[key]);
            }
        });
    }
}
/**
 * Decides if the extended field is hidden based on the current merge strategy.
 *
 * @param form - The form instance.
 * @param fieldName - The name of the field to check.
 * @returns True if the field is hidden, false otherwise.
 */ function isExtendedFieldHiddenBase(form, fieldName) {
    const extendsMergeStrategyPath = [
        TemplateMetaDataField.ExtendedMergeStrategies,
        fieldName
    ];
    const currentMergeStrategy = form.getFieldValue(extendsMergeStrategyPath);
    return [
        undefined,
        EExtendsMergeStrategy.Keep,
        EExtendsMergeStrategy.Clear
    ].includes(currentMergeStrategy);
}
/**
 * Checks if the extended field is hidden based on the current merge strategy if the user has the creator role or the field is not extended.
 *
 * @param fieldName - The name of the field to check.
 * @param hasCreatorRole - Whether the user has the creator role.
 * @returns True if the field is hidden, false otherwise.
 */ function isExtendedFieldHidden(fieldName, hasCreatorRole) {
    return (form)=>{
        const extended = form.getFieldValue(TemplateMetaDataField.Extends);
        if (hasCreatorRole || !extended) {
            return false;
        }
        return isExtendedFieldHiddenBase(form, fieldName);
    };
}
/**
 * Same as isExtendedFieldHidden but for multiple fields.
 *
 * @param fieldNames - The names of the fields to check.
 * @param hasCreatorRole - Whether the user has the creator role.
 * @returns True if the fields are hidden, false otherwise.
 */ function areExtendedFieldsHidden(fieldNames, hasCreatorRole) {
    return (form)=>{
        if (hasCreatorRole || (fieldNames === null || fieldNames === void 0 ? void 0 : fieldNames.length) === 0) {
            return false;
        }
        return fieldNames.every((fieldName)=>isExtendedFieldHiddenBase(form, fieldName));
    };
}
function getInitialFormValueService(serviceTemplate, t) {
    // Transform entity translations to form translations
    let serviceTemplateProviderTranslations = {};
    let serviceTemplatePurposesWithTranslations = [];
    let serviceTemplateTechnicalDefinitionsWithTranslations = [];
    let serviceTemplateDynamicFieldsWithTranslations = [];
    let serviceTemplateTranslations = {};
    if (serviceTemplate) {
        var _serviceTemplate_translations, _serviceTemplate_purposes, _serviceTemplate_technicalDefinitions, _serviceTemplate_dynamicFields;
        serviceTemplateProviderTranslations = mapToFormTranslationEntries((_serviceTemplate_translations = serviceTemplate.translations) === null || _serviceTemplate_translations === void 0 ? void 0 : _serviceTemplate_translations.map((translation)=>{
            return {
                language: translation.language,
                ...structuredClone(translation.provider)
            };
        }));
        serviceTemplatePurposesWithTranslations = (_serviceTemplate_purposes = serviceTemplate.purposes) === null || _serviceTemplate_purposes === void 0 ? void 0 : _serviceTemplate_purposes.map((purpose)=>{
            const translations = mapToFormTranslationEntries(purpose.translations);
            return {
                ...purpose,
                translations
            };
        });
        serviceTemplatePurposesWithTranslations === null || serviceTemplatePurposesWithTranslations === void 0 ? void 0 : serviceTemplatePurposesWithTranslations.sort(SORTER_ORDER_POSITION);
        serviceTemplateTechnicalDefinitionsWithTranslations = (_serviceTemplate_technicalDefinitions = serviceTemplate.technicalDefinitions) === null || _serviceTemplate_technicalDefinitions === void 0 ? void 0 : _serviceTemplate_technicalDefinitions.map((technicalDefinition)=>{
            const translations = mapToFormTranslationEntries(technicalDefinition.translations);
            return {
                ...technicalDefinition,
                translations
            };
        });
        serviceTemplateTechnicalDefinitionsWithTranslations === null || serviceTemplateTechnicalDefinitionsWithTranslations === void 0 ? void 0 : serviceTemplateTechnicalDefinitionsWithTranslations.sort(SORTER_ORDER_POSITION);
        serviceTemplateDynamicFieldsWithTranslations = (_serviceTemplate_dynamicFields = serviceTemplate.dynamicFields) === null || _serviceTemplate_dynamicFields === void 0 ? void 0 : _serviceTemplate_dynamicFields.map((dynamicField)=>{
            const translations = mapToFormTranslationEntries(dynamicField.translations);
            return {
                ...dynamicField,
                translations
            };
        });
        serviceTemplateDynamicFieldsWithTranslations === null || serviceTemplateDynamicFieldsWithTranslations === void 0 ? void 0 : serviceTemplateDynamicFieldsWithTranslations.sort(SORTER_ORDER_POSITION);
        serviceTemplateTranslations = mapToFormTranslationEntries(serviceTemplate === null || serviceTemplate === void 0 ? void 0 : serviceTemplate.translations);
    }
    if (serviceTemplate && !serviceTemplate.successorOfIdentifier) {
        serviceTemplate.successorOfIdentifier = [];
    }
    const value = serviceTemplate ? structuredClone({
        ...serviceTemplate,
        // ===== Translation Properties ======
        provider: {
            ...serviceTemplate.provider,
            translations: serviceTemplateProviderTranslations
        },
        purposes: serviceTemplatePurposesWithTranslations || [],
        technicalDefinitions: serviceTemplateTechnicalDefinitionsWithTranslations || [],
        dynamicFields: serviceTemplateDynamicFieldsWithTranslations || [],
        translations: serviceTemplateTranslations,
        // ===== Translation Properties ======
        minRequiredRcbVersion: (serviceTemplate === null || serviceTemplate === void 0 ? void 0 : serviceTemplate.minRequiredRcbVersion) ? mapVersionToSemver(serviceTemplate === null || serviceTemplate === void 0 ? void 0 : serviceTemplate.minRequiredRcbVersion) : serviceTemplate === null || serviceTemplate === void 0 ? void 0 : serviceTemplate.minRequiredRcbVersion,
        dataProcessingInCountries: serviceTemplate.dataProcessingInCountries ? serviceTemplate.dataProcessingInCountries : [],
        dataProcessingInCountriesSpecialTreatments: serviceTemplate.dataProcessingInCountriesSpecialTreatments ? serviceTemplate.dataProcessingInCountriesSpecialTreatments : [],
        successorOfIdentifier: serviceTemplate.successorOfIdentifier || []
    }) : {
        language: DEFAULT_LANGUAGE,
        isDeleted: false,
        purposes: useServiceTemplatePurposesFromEnum(t),
        dataProcessingInCountries: [],
        dataProcessingInCountriesSpecialTreatments: [],
        minRequiredRcbVersion: "0.0.0",
        executeCodeOptInWhenNoTagManagerConsentIsGiven: false,
        executeCodeOptOutWhenNoTagManagerConsentIsGiven: false,
        group: undefined,
        isEmbeddingOnlyExternalResources: null,
        shouldUncheckContentBlockerCheckbox: false,
        successorOfIdentifier: [],
        executePriority: 10,
        isCdn: false
    };
    initializeTranslationFlags(value);
    return value;
}
/**
 * Resolves a flexible name path for a given language.
 *
 * @param flexNamePath - The flexible name path, which can be a function or a name path.
 * @param lang - The targeted language.
 * @returns The resolved name path.
 */ function resolveFlexNamePath(flexNamePath, lang) {
    // if flexNamePath is a function and lang is provided, resolve using the function
    if (typeof flexNamePath === "function" && lang) {
        return flexNamePath(lang);
    }
    // otherwise, return flexNamePath as is
    return flexNamePath;
}
/**
 * Resolves a key for a given language and name path.
 *
 * @param lang - The supported language.
 * @param path - The name path, which can be an array or a string.
 * @returns A string representing the resolved key.
 */ function resolveNamePathKey(lang, path) {
    // convert language to string and initialize key
    let key = (lang === null || lang === void 0 ? void 0 : lang.toString()) || "";
    // prepare key based on the type of path
    if (Array.isArray(path)) {
        key = `${path.join("-")}-${key}`;
    } else if (path) {
        key = `${path}-${key}`;
    }
    return key;
}
/**
 * Generates path information based on the given flexible name path configuration and language.
 *
 * @param flexNamePath - The flexible path configuration object.
 * @param lang - The targeted language.
 * @returns An object containing detailed path information.
 */ function getPathInfo(flexNamePath, lang) {
    const { absolute, relative, isFormListItem } = flexNamePath;
    const info = {};
    // resolve relative paths
    const relativeNamePath = resolveFlexNamePath(relative === null || relative === void 0 ? void 0 : relative.namePath, lang);
    const relativeCustomFlagNamePath = resolveFlexNamePath(relative === null || relative === void 0 ? void 0 : relative.customFlagNamePath, lang);
    const relativeTranslationNamePath = relative === null || relative === void 0 ? void 0 : relative.translationNamePath; // do not resolve
    // resolve absolute paths
    const absoluteNamePath = resolveFlexNamePath(absolute === null || absolute === void 0 ? void 0 : absolute.namePath, lang);
    const absoluteCustomFlagNamePath = resolveFlexNamePath(absolute === null || absolute === void 0 ? void 0 : absolute.customFlagNamePath, lang);
    const absoluteTranslationNamePath = absolute === null || absolute === void 0 ? void 0 : absolute.translationNamePath; // do not resolve
    // strategy is to use absolute with priority, except for list items
    info.namePath = absoluteNamePath;
    info.flagNamePath = absoluteCustomFlagNamePath || absoluteNamePath;
    info.translationNamePath = absoluteTranslationNamePath;
    if (isFormListItem) {
        // for list items, use relative paths with higher priority
        info.namePath = relativeNamePath || info.namePath;
        info.flagNamePath = relativeCustomFlagNamePath || relativeNamePath || info.flagNamePath;
        info.translationNamePath = relativeTranslationNamePath || info.translationNamePath;
        if (relative === null || relative === void 0 ? void 0 : relative.namePathPrefix) {
            let prefixPath = relative.namePathPrefix;
            let relativePath = info.namePath;
            if (!Array.isArray(prefixPath)) {
                prefixPath = [
                    prefixPath
                ];
            }
            if (!Array.isArray(relativePath)) {
                relativePath = [
                    relativePath
                ];
            }
            info.translationNamePathDefaultLang = [
                ...prefixPath,
                ...relativePath
            ];
        }
    }
    info.translationNamePathDefaultLang = info.translationNamePathDefaultLang || info.namePath;
    // resolve keys
    info.key = resolveNamePathKey(lang, info.namePath);
    info.flagKey = `${resolveNamePathKey(lang, info.flagNamePath)}-flag`;
    return info;
}
/**
 * Generates a NamePathByLanguage function for given field name.
 *
 * @param fieldName - The name of the field.
 * @returns A function that takes a supported language and returns a NamePath using fieldName.
 */ function defaultTranslationFlexNamePath(fieldName) {
    return (lang)=>[
            TRANSLATIONS_PROPERTY_NAME,
            lang,
            fieldName
        ];
}
export { areExtendedFieldsHidden, defaultTranslationFlexNamePath, getInitialFormValueContentBlocker, getInitialFormValueService, getPathInfo, initializeTranslationFlags, isExtendedFieldHidden, mapFromFormDynamicFieldsIntoServiceTemplate, mapFromFormPurposesIntoServiceTemplate, mapFromFormTechnicalDefinitionsIntoServiceTemplate, mapFromFormTranslations, mapFromFormTranslationsIncludingProviderIntoServiceTemplate, mapFromFormTranslationsIntoContentBlockerTemplate, mapToFormTranslationEntries, resolveFlexNamePath, resolveNamePathKey };
